import { Search2Icon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@chakra-ui/react";

export function OCVSearchButton({ ocvsize }: Record<string, any>): JSX.Element {
  const navigate = useNavigate();
  return (
    <IconButton
      colorScheme="white"
      aria-label="Search for website"
      icon={<Search2Icon boxSize={ocvsize} />}
      onClick={() => navigate("/ocvSearch")}
    />
  );
}
