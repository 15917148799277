// This hook enables Google Analytics page tracking.
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const usePageTracking = (): void => {
  const GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string;

  ReactGA.initialize(GA_ID);
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });

    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [location]);
};
