import { Box, SkeletonCircle, SkeletonText, HStack } from "@chakra-ui/react";
export function FooterLoading({ manifest }: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      aria-label="footerskeleton"
      padding="5"
      bg={primaryColor}
    >
      <HStack>
        <SkeletonCircle
          m="5px"
          size="90"
          float="left"
        />
        <SkeletonText
          width="33%"
          ml="100px"
          mt="4px"
          noOfLines={1}
          spacing="3"
          skeletonHeight="50"
        />
        <SkeletonText
          width="33%"
          mt="4px"
          noOfLines={1}
          spacing="3"
          skeletonHeight="50"
        />
        <SkeletonText
          width="33%"
          mt="4px"
          noOfLines={1}
          spacing="3"
          skeletonHeight="50"
        />
      </HStack>
    </Box>
  );
}
