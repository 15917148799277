import { Box, Skeleton, SkeletonText, HStack } from "@chakra-ui/react";
export function FeatureBarLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      alignContent={"center"}
      justifyContent={"center"}
      padding="5px"
      height="325px"
      mt="10px"
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <SkeletonText
          width="300px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="5"
        />
      </Box>
      <HStack
        align={"center"}
        mt="15px"
        mb="15px"
      >
        <Skeleton
          height="200px"
          width="200px"
        />
        <Skeleton
          height="200px"
          width="200px"
        />
        <Skeleton
          height="200px"
          width="200px"
        />
        <Skeleton
          height="200px"
          width="200px"
        />
      </HStack>
    </Box>
  );
}
