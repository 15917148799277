import {
  HStack,
  Box,
  Skeleton,
  SkeletonText,
  Grid,
  GridItem,
} from "@chakra-ui/react";
export function BlogCardLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="500px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <SkeletonText
          width="300px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="7"
        />
      </Box>

      <Grid
        display="grid"
        mt="15px"
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(1, 1fr)"
        gap={4}
      >
        <GridItem
          rowSpan={1}
          gap={8}
        >
          <HStack>
            <SkeletonText
              width="500px"
              noOfLines={5}
              spacing="4"
              skeletonHeight="5"
            />
            <Skeleton
              width="175px"
              height="50px"
            />
          </HStack>
        </GridItem>
        <GridItem rowSpan={1}>
          <HStack>
            <Skeleton
              width="375px"
              height="125px"
              m="10px"
            />
            <Skeleton
              width="375px"
              height="125px"
              m="10px"
            />
          </HStack>
        </GridItem>
        <GridItem rowSpan={1}>
          <HStack>
            <Skeleton
              width="375px"
              height="125px"
              m="10px"
            />
            <Skeleton
              width="375px"
              height="125px"
              m="10px"
            />
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  );
}
