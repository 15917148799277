import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Link } from "@chakra-ui/react";

interface LinkProps {
  children: React.ReactNode;
  url?: string;
  external?: boolean;
  type?: any;
  button?: boolean;
  $primary?: string;
}

export const StyledLink = styled(NavLink)<LinkProps>`
  cursor: pointer;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: none;
  padding: 0.75rem;
  min-width: min-content;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;
export const StyledAnchor = styled(Link)<LinkProps>`
  cursor: pointer;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: none;
  padding: 0.75rem;
  min-width: min-content;
  height: 100%;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

export const ExternalLink = styled.a<LinkProps>`
  cursor: pointer;
  font-family: Roboto;
  font-weight: 500;
  text-decoration: none;
  padding: 0.75rem;
  min-width: min-content;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

// background-color: props.secondary
// border: .. .. props.secondary
// color: props.primary
export const ButtonLink = styled(StyledLink)<LinkProps>`
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3rem;
  color: ${({ $primary }) => $primary};
  font-size: 0.75rem;
  font-weight: bolder;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  transition: 0.2s;

  &:hover {
    transform: translateY(-3px);
    text-decoration: none;
    color: initial;
  }
`;
