import styled from "styled-components/macro";

interface Props {
  $primary?: string;
  $secondary?: string;
  $tertiary?: string;
  $length?: number;
}

export const DropdownContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
`;

export const DropdownMenu = styled.div`
  background: #f0f0f0;
  border-radius: 0 0 6px 6px;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: stretch;
`;

export const ColumnHeader = styled.h2<Props>`
  color: ${({ $primary }) => $primary};
  font-size: 20px;
  font-weight: 900;
  padding-left: 7px;
`;

export const DropdownColumn = styled.div<Props>`
  width: calc(${(props) => 100 / Number(props?.$length)}% - 30px);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 12px 0px;

  &:not(:last-child) {
    border-right: 2px solid ${({ $primary }) => $primary};
  }
`;

export const DropdownList = styled.ul<Props>`
  min-height: 200px;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, auto);
  gap: 0px 10px;
  width: auto;

  li {
    box-sizing: content-box;
    padding: 0;
    width: auto;
    height: auto;
    border-left: 2px transparent solid;

    &:hover,
    &:focus {
      border-radius: 0;
      border-left: 2px ${({ $primary }) => $primary} solid;
    }

    a {
      padding: 5px;
      color: ${({ $primary }) => $primary};
      font-size: 0.9rem;
      display: block;
      text-align: left;
      width: 100%;
      height: 100%;

      &:hover,
      &:focus {
        color: ${({ $primary }) => $primary};
      }
    }
  }
`;
