import { Box, Skeleton, HStack, SkeletonText } from "@chakra-ui/react";
export function SocialBarLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <HStack align={"center"}>
        <SkeletonText
          width="450px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="5"
        />
        <Skeleton
          height="50px"
          width="50px"
        />
        <Skeleton
          height="50px"
          width="50px"
        />
      </HStack>
    </Box>
  );
}
