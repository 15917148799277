import React from "react";
import styled from "styled-components";
import {
  Link,
  Button,
  HStack,
  Image,
  Box,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { SocialIcons } from "../../components/SocialIcons/SocialIcons";
import { NavLink } from "react-router-dom";

interface IVerticalMarket {
  [id: string]: {
    [id2: string]: any;
  };
}
const s3 = "https://ocv-web-icons.s3.us-east-2.amazonaws.com/";
const verticalMarkets: IVerticalMarket = {
  sheriff: {
    href: "https://thesheriffapp.com",
    alt: "The Sheriff App",
    img: s3 + "TheSheriffAppCom.png",
    width: "500px",
  },
  police: {
    href: "https://thepoliceapp.com",
    alt: "The Police App",
    img: s3 + "ThePoliceApp.png",
    width: "250px",
  },
  ema: {
    href: "https://myemaapp.com",
    alt: "My EMA App",
    img: s3 + "MyEMAApp.png",
    width: "250px",
  },
  fire: {
    href: "https://thefiredepartmentapp.com",
    alt: "The Fire Department App",
    img: s3 + "TheFireDepartment.png",
    width: "250px",
  },
  health: {
    href: "https://thepublichealthapp.com",
    alt: "The Public Health App",
    img: s3 + "ThePublicHealthApp.png",
    width: "250px",
  },
  ocv: {
    href: "https://ocvapps.com",
    alt: "OCV Apps",
    img: s3 + "ocvLLCWhite.png",
    width: "200px",
  },
  ocvBlue: {
    href: "https://ocvapps.com",
    alt: "OCV Apps",
    img: s3 + "OCVBlue.png",
    width: "200px",
  },
  gov: {
    href: "https://www.thegovapp.com/",
    alt: "Gov Apps",
    img: s3 + "GovApps.png",
    width: "250px",
  },
  utilities: {
    href: "https://theutilityapp.com/",
    alt: "Gov Apps",
    img: s3 + "UtilitiesLogo.png",
    width: "125px",
  },
};

export const FooterHeader = styled.h2`
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: Fjalla One;
  letter-spacing: 0.1rem;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  padding-left: 0.5rem;
`;
export const Item = styled.h2`
  text-transform: uppercase;
  font-size: 2.25rem;
  font-family: Fjalla One;
  text-align: left;
`;

export const External = styled(Link)`
  line-height: 2rem;
`;
export const Internal = styled(NavLink)`
  line-height: 2rem;
  &:hover {
    text-decoration: underline;
  }
`;
export const PoweredBy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  width: 100%;
`;
export default function Footer(props: Record<string, any>): JSX.Element {
  const { manifestData } = props;
  const footerConfig = manifestData?.views?.footer?.config;
  const navConfig = manifestData?.views?.navbar?.config;
  const primary = manifestData?.stylesheet?.colors?.primary;
  const tertiary = manifestData?.stylesheet?.colors?.tertiary;
  const logo = manifestData?.stylesheet?.images?.titleImage?.url;
  const altText = manifestData?.stylesheet?.images?.titleImage?.altText;
  return (
    <footer id={props?.anchorID}>
      <Box
        bgColor={primary}
        color="white"
        py="1rem"
        px="3rem"
        borderTopWidth="4px"
        borderTopColor={tertiary}
      >
        <SimpleGrid
          minChildWidth={
            footerConfig?.minChildWidth ? footerConfig?.minChildWidth : "250px"
          }
          spacing="40px"
        >
          <NavLink
            to={{
              pathname: `/`,
            }}
          >
            <HStack>
              <Image
                src={logo}
                alt={altText}
                title="Go to Homepage"
                maxWidth={
                  footerConfig?.imgWidth ? footerConfig?.imgWidth : "75px"
                }
              />
              <FooterHeader
                dangerouslySetInnerHTML={{
                  __html: navConfig?.title,
                }}
              />
            </HStack>
            <p
              style={{ lineHeight: "1.5rem" }}
            >{`COPYRIGHT © ${new Date().getFullYear()} OCV, LLC`}</p>
            {footerConfig?.title && (
              <p style={{ lineHeight: "1.5rem" }}>
                & {footerConfig?.title.toUpperCase()}
              </p>
            )}

            <Text fontSize="xs">v.{process.env.REACT_APP_VERSION}</Text>
          </NavLink>

          {footerConfig?.item1?.title && (
            <div id="Column1">
              <Item
                dangerouslySetInnerHTML={{
                  __html: footerConfig?.item1?.title,
                }}
              />
              <div>
                <External
                  href="https://myocv.com/accessibility"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Accessibility
                </External>
              </div>
              <div>
                <External
                  href="https://myocv.com/eula"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EULA
                </External>
              </div>
              <div>
                <External
                  href="https://myocv.com/privacyPolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </External>
              </div>
              {footerConfig?.item1?.options?.map(
                (option: any, index: number) => (
                  <div key={option + index}>
                    {option?.feature && (
                      <Internal to={option?.feature}>
                        {option?.featureText}
                      </Internal>
                    )}
                    {option?.url && (
                      <External
                        href={option?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {option?.urlText}
                      </External>
                    )}
                  </div>
                )
              )}
            </div>
          )}
          {footerConfig?.item2?.title && (
            <div id="Column2">
              <Item
                dangerouslySetInnerHTML={{
                  __html: footerConfig?.item2?.title,
                }}
              />
              {footerConfig?.item2?.address && (
                <External
                  title="Click to open on Google Maps"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`http://maps.google.com/?q=${footerConfig?.item2?.address}`}
                  dangerouslySetInnerHTML={{
                    __html: footerConfig?.item2?.address,
                  }}
                />
              )}
              <div>
                <External
                  href={`tel:${footerConfig?.item2?.phone}`}
                  title={`Call us at ${footerConfig?.item2?.phone}`}
                >
                  {footerConfig?.item2?.phoneName} {footerConfig?.item2?.phone}
                </External>
              </div>

              {footerConfig?.item2?.phone2 ? (
                <div>
                  <External
                    href={`tel:${footerConfig?.item2?.phone2}`}
                    title={`Call us at ${footerConfig?.item2?.phone2}`}
                  >
                    {footerConfig?.item2?.phoneName2}{" "}
                    {footerConfig?.item2?.phone2}
                  </External>
                </div>
              ) : null}
              <div>
                <External
                  href={`mailto:${footerConfig?.item2?.email}`}
                  title={`Email us at ${footerConfig?.item2?.email}`}
                >
                  {footerConfig?.item2?.email}
                </External>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: footerConfig?.item2?.businessHours,
                }}
                style={{ lineHeight: "2rem" }}
              />
            </div>
          )}
          {footerConfig?.item3?.title && (
            <div id="Column3">
              <Item
                dangerouslySetInnerHTML={{
                  __html: footerConfig?.item3?.title,
                }}
              />
              {footerConfig?.item3?.options?.map(
                (option: any, index: number) => (
                  <div key={option + index}>
                    {option?.feature && (
                      <Internal to={option?.feature}>
                        {option?.featureText}
                      </Internal>
                    )}
                    {option?.url && (
                      <External
                        href={option?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {option?.urlText}
                      </External>
                    )}
                  </div>
                )
              )}
            </div>
          )}
          {footerConfig?.socialMedia || footerConfig?.item4?.buttonLink ? (
            <div id="Column4">
              <Item
                dangerouslySetInnerHTML={{
                  __html: footerConfig?.item4?.title,
                }}
              />
              <HStack
                align="center"
                p="1em"
              >
                {footerConfig?.socialMedia?.map(
                  (
                    item: { icon: string; url: string; name: string },
                    index: number
                  ) => (
                    <Link
                      href={item.url}
                      key={item.icon + index}
                      target="_blank"
                      aria-label={`Link to ${item.name}`}
                    >
                      {SocialIcons(item.icon, "40px")}
                    </Link>
                  )
                )}
              </HStack>
              {footerConfig?.item4?.buttonLink && (
                <Link
                  href={footerConfig?.item4?.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    bgColor={footerConfig?.item4?.buttonColor}
                    color={footerConfig?.item4?.buttonTextColor}
                    borderColor={footerConfig?.item4?.buttonBorderColor}
                    min-width="15rem"
                    borderWidth="1px"
                  >
                    {footerConfig?.item4?.buttonText.toUpperCase()}
                  </Button>
                </Link>
              )}
            </div>
          ) : null}
        </SimpleGrid>
      </Box>

      {footerConfig?.vertical && (
        <PoweredBy>
          {footerConfig?.vertical != "sheriff" && <p> Powered By:</p>}
          <a
            rel="noopener noreferrer"
            href={
              verticalMarkets[footerConfig?.vertical]
                ? verticalMarkets[footerConfig?.vertical]?.href
                : verticalMarkets["ocv"]?.href
            }
            target={"_blank"}
          >
            <img
              src={verticalMarkets[footerConfig?.vertical]?.img}
              alt={verticalMarkets[footerConfig?.vertical]?.alt}
              width={verticalMarkets[footerConfig?.vertical]?.width}
            />
          </a>
        </PoweredBy>
      )}
    </footer>
  );
}
