import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";
export function VideoPlayerLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest?.["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="500px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
        mb="15px"
      >
        <SkeletonText
          width="300px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="5"
        />
      </Box>
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <Skeleton
          width="800px"
          height="500px"
        />
      </Box>
    </Box>
  );
}
