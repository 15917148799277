import { Box, HStack, Skeleton, SkeletonText } from "@chakra-ui/react";
export function SocialMediaLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      padding="5px"
      mt="15px"
      height="525px"
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <SkeletonText
          mt="5px"
          noOfLines={1}
          width="700px"
          alignContent={"center"}
          spacing="5"
          skeletonHeight="5"
        />
      </Box>
      <HStack mt="15px">
        <Skeleton
          height="400px"
          width="400px"
        />
        <Skeleton
          height="400px"
          width="400px"
        />
        <Skeleton
          height="400px"
          width="400px"
        />
      </HStack>
    </Box>
  );
}
