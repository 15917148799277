import { Box, Skeleton, Stack } from "@chakra-ui/react";
export default function PageLoading(): JSX.Element {
  return (
    <Box
      padding="6"
      bg="#eee"
    >
      <Stack
        marginTop="2rem"
        minHeight="450px"
        align="center"
      >
        <Skeleton
          height="100px"
          width="83%"
          startColor="black"
          endColor="white"
        />
        <br />
        <Skeleton
          height="20px"
          width="83%"
          startColor="black"
          endColor="white"
        />
        <Skeleton
          height="20px"
          width="83%"
          startColor="black"
          endColor="white"
        />
        <Skeleton
          height="20px"
          width="83%"
          startColor="black"
          endColor="white"
        />
      </Stack>
    </Box>
  );
}
