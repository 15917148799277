import {
  Box,
  Grid,
  GridItem,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  HStack,
} from "@chakra-ui/react";
export function ContactCardLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="550px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={6}
        >
          <GridItem>
            <Box
              display="grid"
              justifyContent={"center"}
            >
              <Skeleton
                width="225px"
                height="200px"
                mb="20px"
              />
            </Box>
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="10px"
            />
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="5"
              mb="10px"
            />
            <Grid>
              <GridItem>
                <HStack>
                  <SkeletonCircle mb="10px" />
                  <SkeletonText
                    mb="10px"
                    width="300px"
                    noOfLines={1}
                    spacing="4"
                    skeletonHeight="5"
                  />
                </HStack>
              </GridItem>
              <GridItem>
                <HStack>
                  <SkeletonCircle mb="10px" />
                  <SkeletonText
                    mb="10px"
                    width="300px"
                    noOfLines={1}
                    spacing="4"
                    skeletonHeight="5"
                  />
                </HStack>
              </GridItem>
              <GridItem>
                <HStack>
                  <SkeletonCircle mb="10px" />
                  <SkeletonText
                    mb="10px"
                    width="300px"
                    noOfLines={1}
                    spacing="4"
                    skeletonHeight="5"
                  />
                </HStack>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <Skeleton
              width="450px"
              height="325px"
            />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}
