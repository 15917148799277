import styled from "styled-components/macro";

interface Props {
  $primary?: string;
  $secondary?: string;
  $tertiary?: string;
  $length?: number;
}

export const DropdownContainer = styled.div`
  min-width: 100%;
  position: absolute;
  opacity: 0;
  visibility: hidden;
`;

export const DropdownMenu = styled.div`
  background: #f0f0f0;
  border-radius: 0 6px 6px 6px;
  border-top: 2px #f0f0f0 solid;
`;

export const DropdownList = styled.ul<Props>`
  display: flex;
  flex-direction: column;

  li {
    color: ${({ $primary }) => $primary};
    padding: 0;
    white-space: nowrap;
    border-left: 3px transparent solid;

    &:not(:last-child) {
      border-bottom: 2px ${({ $primary }) => $primary} solid;
    }

    &:hover {
      color: #f0f0f0;
      background-color: ${({ $primary }) => $primary};
      border-radius: 0;
      border-left: 3px ${({ $tertiary }) => $tertiary} solid;
    }

    a {
      padding: 0.9rem;
      font-size: 0.9rem;
      display: inline-block;
      text-align: left;
      width: 100%;
      height: 100%;
    }
  }
`;
