import { Box, Skeleton, SkeletonText, Grid, GridItem } from "@chakra-ui/react";
export function TwoColumnButtonLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="500px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <Grid
        display="grid"
        mt="15px"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem
          display="grid"
          mt="15px"
        >
          <SkeletonText
            width="450px"
            noOfLines={1}
            spacing="4"
            skeletonHeight="7"
            mb="25px"
          />
          <SkeletonText
            width="450px"
            mt="10px"
            noOfLines={6}
            spacing="3"
            skeletonHeight="4"
          />
          <Box
            display="grid"
            alignContent={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            mt="15px"
          >
            <Skeleton
              mt="15px"
              height="35px"
              width="175px"
            />
          </Box>
        </GridItem>
        <GridItem
          display="grid"
          mt="15px"
        >
          <Skeleton
            width="350px"
            height="200px"
            ml="5px"
          />
        </GridItem>
      </Grid>
    </Box>
  );
}
