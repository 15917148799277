import {
  Box,
  HStack,
  Skeleton,
  SkeletonText,
  Grid,
  GridItem,
} from "@chakra-ui/react";

export function NewsDigestLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="675px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      padding="5px"
      mt="15px"
      bg={primaryColor}
    >
      <Grid
        display="grid"
        alignContent={"center"}
        justifyContent={"center"}
        mt="15px"
        mb="25px"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem
          display="grid"
          colSpan={2}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <SkeletonText
            mt="5px"
            noOfLines={1}
            width="700px"
            alignContent={"center"}
            spacing="5"
            skeletonHeight="5"
          />
        </GridItem>
        <GridItem
          display="grid"
          colSpan={2}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <HStack
            align={"center"}
            mt="15px"
            mb="15px"
          >
            <Skeleton
              height="400px"
              width="400px"
            />
            <Skeleton
              height="400px"
              width="400px"
            />
            <Skeleton
              height="400px"
              width="400px"
            />
          </HStack>
        </GridItem>
      </Grid>
      <Box
        display="grid"
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        mt="5px"
        mb="15px"
      >
        <Skeleton
          mt="15px"
          height="35px"
          width="175px"
        />
      </Box>
    </Box>
  );
}
