import { Box, SkeletonText } from "@chakra-ui/react";
export function LabelLoading({ manifest }: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      height="100px"
      display="grid"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <SkeletonText
        width="450px"
        noOfLines={1}
        spacing="4"
        skeletonHeight="5"
      />
    </Box>
  );
}
