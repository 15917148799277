import { StyledLink } from "../../Links/Links";
import { DropdownArrow } from "../styles";
import { Submenu } from "../Submenu/Submenu";
import { MegaMenu } from "../MegaMenu/MegaMenu";

interface Props {
  featuresList: Record<string, any>;
  item: string;
  colors: Record<string, string>;
  index: number;
  tabIndex?: number;
}

export const Dropdown = ({
  featuresList,
  item,
  index,
  colors,
}: Props): JSX.Element => {
  const header = (
    <StyledLink
      as="span"
      tabIndex={0}
      role="button"
      key={featuresList[item].navHeader + index}
    >
      {featuresList[item].navHeader}
      <DropdownArrow></DropdownArrow>
    </StyledLink>
  );

  let drop;
  if (featuresList[item].type === "submenu")
    drop = (
      <Submenu
        featuresList={featuresList}
        item={item}
        key={featuresList[item].type + index}
        colors={colors}
      />
    );
  else {
    drop = (
      <MegaMenu
        featuresList={featuresList}
        item={item}
        key={featuresList[item].type + index}
        colors={colors}
      />
    );
  }

  const content = [header, drop];

  return <>{content}</>;
};
