import { Box, Skeleton, SkeletonText, Grid, GridItem } from "@chakra-ui/react";
export function BlogCarouselLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      padding="5px"
      height="500px"
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <SkeletonText
          width="400px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="5"
        />
      </Box>
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <Grid
          padding="10px"
          display="grid"
          mt="15px"
          templateColumns="repeat(2, 1fr)"
          gap={4}
        >
          <GridItem gap={4}>
            <SkeletonText
              width="300px"
              noOfLines={2}
              spacing="4"
              skeletonHeight="7"
              mb="15px"
            />
            <SkeletonText
              width="450px"
              noOfLines={6}
              spacing="4"
              skeletonHeight="5"
            />
          </GridItem>
          <GridItem
            mt="15px"
            ml="25px"
            padding="10px"
            justifyContent={"center"}
          >
            <Skeleton
              width="250px"
              height="250px"
            />
          </GridItem>
        </Grid>
      </Box>
      <Box
        display="grid"
        mb="10px"
        mt="10px"
        justifyContent={"center"}
      >
        <Skeleton
          width="150px"
          height="50px"
        />
      </Box>
    </Box>
  );
}
