import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";
export function PossibilityBoxLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      padding="5px"
      height="400px"
      bg={primaryColor}
    >
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <SkeletonText
          mt="5px"
          alignContent={"center"}
          noOfLines={1}
          width="350px"
          spacing="2"
          skeletonHeight="6"
        />
      </Box>
      <Box
        display="grid"
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        mt="20px"
      >
        <SkeletonText
          mt="5px"
          alignContent={"center"}
          noOfLines={6}
          width="600px"
          spacing="2"
          skeletonHeight="4"
        />
      </Box>
      <Box
        display="grid"
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        mt="15px"
      >
        <Skeleton
          mt="15px"
          height="35px"
          width="175px"
        />
      </Box>
    </Box>
  );
}
