import {
  Box,
  SkeletonCircle,
  SkeletonText,
  Grid,
  GridItem,
} from "@chakra-ui/react";
export function WelcomeLoading(): JSX.Element {
  return (
    <Box
      display="grid"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      padding="5px"
      marginLeft="15px"
      height="500px"
      mt="10px"
      bg="white"
    >
      <Grid
        display="grid"
        alignContent={"center"}
        justifyContent={"center"}
        mt="15px"
        mb="25px"
        h="200px"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem
          display="grid"
          colSpan={2}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <SkeletonText
            mt="5px"
            noOfLines={1}
            width="700px"
            alignContent={"center"}
            spacing="5"
            skeletonHeight="10"
          />
          <SkeletonText
            mt="5px"
            noOfLines={1}
            width="700px"
            alignContent={"center"}
            spacing="5"
            skeletonHeight="5"
          />
        </GridItem>
        <GridItem
          display="grid"
          alignContent={"center"}
          justifyContent={"center"}
        >
          <SkeletonText
            mt="5px"
            alignContent={"center"}
            noOfLines={6}
            width="500px"
            spacing="2"
            skeletonHeight="4"
          />
          <SkeletonText
            mt="5px"
            alignContent={"center"}
            noOfLines={6}
            width="500px"
            spacing="2"
            skeletonHeight="4"
          />
        </GridItem>
        <GridItem
          display="grid"
          alignContent={"center"}
          justifyContent={"center"}
        >
          <SkeletonCircle
            m="5px"
            alignContent={"center"}
            size="150px"
          />
        </GridItem>
      </Grid>
    </Box>
  );
}
