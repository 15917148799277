import ImageGallery from "react-image-gallery";
import "/node_modules/react-image-gallery/styles/css/image-gallery.css";
import "./Slider.css";
import { Link } from "@chakra-ui/react";

export default function Slider({
  sliderConfig,
  anchorID,
}: Record<string, any>): JSX.Element {
  const images = sliderConfig.items.map(
    (item: { url: string | undefined; altText: string | undefined }) => {
      return {
        original: item.url,
        originalAlt: item.altText,
        originalClass: "SliderDiv",
      };
    }
  );

  return (
    <section id={anchorID}>
      <Link
        href={sliderConfig?.external}
        isExternal
        title={sliderConfig?.external}
      >
        <ImageGallery
          items={images}
          showPlayButton={false}
          showFullscreenButton={false}
          autoPlay={true}
          slideInterval={sliderConfig.interval ?? 5000}
          showThumbnails={false}
          showNav={false}
          lazyLoad={true}
        />
      </Link>
    </section>
  );
}
