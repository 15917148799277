const s3 = "https://ocv-web-icons.s3.us-east-2.amazonaws.com/";

const white =
  "invert(100%) sepia(0%) saturate(0%) hue-rotate(316deg) brightness(106%) contrast(102%)";
const twitterBlue =
  "invert(76%) sepia(73%) saturate(6070%) hue-rotate(177deg) brightness(101%) contrast(90%)";
const facebookBlue =
  "invert(36%) sepia(79%) saturate(634%) hue-rotate(185deg) brightness(86%) contrast(79%)";

export const SocialIcons = (icon: string, iconHeight: string): any => {
  switch (icon) {
    case "youtube":
      return (
        <img
          src={s3 + "youtube-square.svg"}
          alt="YouTube"
          style={{ filter: white, height: iconHeight, width: iconHeight }}
        />
      );
    case "facebook-blue":
      return (
        <img
          src={s3 + "facebook-square.svg"}
          alt="Facebook"
          style={{
            filter: facebookBlue,
            height: iconHeight,
            width: iconHeight,
          }}
        />
      );
    case "facebook":
      return (
        <img
          src={s3 + "facebook-square.svg"}
          alt="Facebook"
          style={{ filter: white, height: iconHeight, width: iconHeight }}
        />
      );
    case "facebookCircle":
      return (
        <img
          src={s3 + "FacebookCircle.png"}
          alt="Facebook"
          style={{ height: iconHeight, width: iconHeight }}
        />
      );
    case "instagram":
      return (
        <img
          src={s3 + "instagram-square.svg"}
          alt="Instagram"
          style={{ filter: white, height: iconHeight, width: iconHeight }}
        />
      );
    case "tiktok":
      return (
        <img
          src={s3 + "tiktok.svg"}
          alt="Twitter"
          style={{ filter: white, height: iconHeight, width: iconHeight }}
        />
      );
    case "twitter":
      return (
        <img
          src={s3 + "twitter-square.svg"}
          alt="Twitter"
          style={{ filter: white, height: iconHeight, width: iconHeight }}
        />
      );
    case "twitter-blue":
      return (
        <img
          src={s3 + "twitter-square.svg"}
          alt="Twitter"
          style={{ filter: twitterBlue, height: iconHeight, width: iconHeight }}
        />
      );
    case "twitterCircle":
      return (
        <img
          src={s3 + "TwitterCircle.png"}
          alt="Twitter"
          style={{ height: iconHeight, width: iconHeight }}
        />
      );
    case "apple":
      return (
        <img
          src={s3 + "apple.svg"}
          alt="Apple/iOS App store"
          style={{ filter: white, height: iconHeight, width: iconHeight }}
        />
      );
    case "google":
      return (
        <img
          src={s3 + "google-play.svg"}
          alt="Google Play store"
          style={{ filter: white, height: iconHeight, width: iconHeight }}
        />
      );
    case "bell":
      return (
        <img
          src={s3 + "bell-solid.svg"}
          alt="Alert or Push notifications"
          style={{
            height: iconHeight,
          }}
        />
      );
    case "newspaper":
      return (
        <img
          src={s3 + "newspaper-solid.svg"}
          alt="Blog post/News"
          style={{ height: iconHeight }}
        />
      );
    case "newsDigest":
      return (
        <img
          src={s3 + "NewsDigest.svg"}
          alt="Blog post/News"
          style={{ height: iconHeight }}
        />
      );
    case "calendar":
      return (
        <img
          src={s3 + "calendar-alt-solid.svg"}
          alt="Calendar Event"
          style={{
            height: iconHeight,
          }}
        />
      );
    case "calendar2":
      return (
        <img
          src={s3 + "calendar@2x.png"}
          alt="Calendar Event"
          style={{
            height: iconHeight,
          }}
        />
      );
    default:
      console.error("The icon you requested is not in our SocialIcon file.");
      return <span>The icon you requested is not in our SocialIcon file.</span>;
  }
};
