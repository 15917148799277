import {
  VStack,
  HStack,
  Box,
  Skeleton,
  SkeletonText,
  Grid,
  GridItem,
} from "@chakra-ui/react";
export function DownloadOurAppLoading({
  manifest,
}: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      height="500px"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={primaryColor}
    >
      <Grid
        display="grid"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem display="grid">
          <Skeleton
            width="350px"
            height="200px"
            ml="5px"
          />
        </GridItem>
        <GridItem display="grid">
          <SkeletonText
            width="450px"
            mt="10px"
            noOfLines={1}
            spacing="3"
            skeletonHeight="7"
          />
          <SkeletonText
            width="450px"
            mt="10px"
            noOfLines={3}
            spacing="3"
            skeletonHeight="4"
          />
          <HStack m="10px">
            <VStack>
              <Skeleton
                width="150px"
                height="50px"
              />
              <Skeleton
                width="150px"
                height="50px"
              />
            </VStack>
            <Skeleton
              width="150px"
              height="150px"
            />
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  );
}
