import { Box, Skeleton, SkeletonText, Grid, GridItem } from "@chakra-ui/react";
export function BlogFAQLoading({ manifest }: Record<string, any>): JSX.Element {
  const primaryColor = manifest["stylesheet"]["colors"]["primary"];

  return (
    <Box
      display="grid"
      padding="5px"
      height="500px"
      bg={primaryColor}
    >
      <Box
        mt="15px"
        mb="20px"
        display="grid"
        justifyContent={"center"}
      >
        <SkeletonText
          width="300px"
          noOfLines={1}
          spacing="4"
          skeletonHeight="5"
        />
      </Box>
      <Box
        display="grid"
        justifyContent={"center"}
      >
        <Grid
          display="grid"
          templateColumns="repeat(2, 1fr)"
          templateRows="repeat(3, 1fr)"
          gap={4}
        >
          <GridItem
            rowSpan={1}
            gap={4}
          >
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="15px"
            />
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="30px"
            />
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="15px"
            />
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="15px"
            />
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="15px"
            />
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="15px"
            />
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="15px"
            />
            <Box
              display="grid"
              justifyContent={"right"}
              mr="5px"
            >
              <Skeleton
                width="150px"
                height="25px"
                mb="15px"
              />
            </Box>
          </GridItem>
          <GridItem
            rowSpan={1}
            gap={4}
          >
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="15px"
            />
            <SkeletonText
              width="400px"
              noOfLines={1}
              spacing="4"
              skeletonHeight="7"
              mb="30px"
            />
            <SkeletonText
              width="400px"
              noOfLines={6}
              spacing="4"
              skeletonHeight="4"
              mb="15px"
              ml="10px"
            />
            <SkeletonText
              width="400px"
              noOfLines={4}
              spacing="4"
              skeletonHeight="4"
              mb="15px"
              ml="10px"
            />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
}
